import React from "react";
import Loadable from "react-loadable";
import { Route, Switch, BrowserRouter } from "react-router-dom";

const Main = Loadable({
  loader: () => import(/* webpackChunkName: "Main" */ "./Main"),
  loading: () => <div></div>,
  modules: ["Main"],
});

function App() {
  return (
    <React.Fragment>
      <BrowserRouter basename={"/tokenupdate"}>
        <Switch>
          <Route exact path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
